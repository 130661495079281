import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout'),
    children: [
      {
        path: '',
        redirect: '/tasks'
      },
      {
        path: 'tasks',
        name: 'tasks',
        meta: { auth: true },
        component: () => import('../views/TasksView.vue')
      },
      {
        path: 'activity',
        name: 'activity',
        meta: { auth: true },
        component: () => import('../views/ActivityView.vue')
      },
      {
        path: 'settings',
        name: 'settings-view',
        meta: { auth: true },
        component: () => import('../views/SettingsView')
      },
      {
        path: 'books',
        name: 'books-view',
        meta: { auth: true },
        component: () => import('../views/BooksView')
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { auth: true },
        component: () => import('../views/ProfileView')
      },
      {
        path: 'planner',
        name: 'planner',
        meta: { auth: true },
        component: () => import('../views/PlannerView')
      },
      {
        path: 'ideas',
        name: 'ideas',
        meta: { auth: true },
        component: () => import('../views/IdeasView')
      },
      {
        path: 'models',
        name: 'models',
        meta: { auth: true },
        component: () => import('../views/ModelsView')
      },
      {
        path: 'studies',
        name: 'studies',
        meta: { auth: true },
        component: () => import('../views/StudiesView')
      },
      {
        path: 'projects',
        meta: { auth: true },
        children: [
          {
            path: '',
            name: 'projects',
            component: () => import('../views/ProjectsView'),
          },
          {
            path: ':id',
            component: () => import('../layouts/ProjectLayout'),
            children: [
              {
                path: '',
                redirect: (to) => {
                  return { path: `/projects/${to.params.id}/details` };
                }
              },
              {
                path: 'details',
                name: 'details',
                meta: { auth: true },
                component: () => import('../views/ProjectDetails')
              },
              {
                path: 'settings',
                name: 'settings',
                meta: { auth: true },
                component: () => import('../views/ProjectSettings')
              },
              {
                path: 'strategy',
                name: 'strategy',
                meta: { auth: true },
                component: () => import('../views/ProjectStrategy')
              },
              {
                path: 'planning',
                name: 'planning',
                meta: { auth: true },
                component: () => import('../views/ProjectPlanning')
              },
              {
                path: 'mvp',
                name: 'mvp',
                meta: { auth: true },
                component: () => import('../views/ProjectMvp')
              },
              {
                path: 'crms',
                name: 'crms',
                meta: { auth: true },
                component: () => import('../views/ProjectCrm')
              },
              {
                path: 'tech',
                name: 'tech',
                meta: { auth: true },
                component: () => import('../views/ProjectTech')
              },
              {
                path: 'decisions',
                name: 'decisions',
                meta: { auth: true },
                component: () => import('../views/ProjectDecisions')
              },
              {
                path: 'docs',
                name: 'docs',
                meta: { auth: true },
                component: () => import('../views/ProjectDocs')
              },
              {
                path: 'activities',
                name: 'activities',
                meta: { auth: true },
                component: () => import('../views/ProjectActivities')
              },
              {
                path: 'notes',
                name: 'notes',
                meta: { auth: true },
                component: () => import('../views/ProjectNotes')
              },
              {
                path: 'meetings',
                name: 'meetings',
                meta: { auth: true },
                component: () => import('../views/ProjectMeetings')
              },
              {
                path: 'participants',
                name: 'participants',
                meta: { auth: true },
                component: () => import('../views/ProjectParticipants')
              },
              {
                path: 'links',
                name: 'links',
                meta: { auth: true },
                component: () => import('../views/ProjectLinks')
              }
            ]
          }
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/register-success/:id/:email',
    name: 'register-success',
    component: () => import('../views/RegisterSuccess.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', next)
    : next();
});

export default router;
