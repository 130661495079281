import axios from "axios"
import api from "@/store/modules/api"

export const state = {
  data: [],
  activity: {name: '', status: ''},
  tableHeader: [
    {name: 'name'},
    {name: 'client'},
    {name: 'phase'},
    {name: 'type'},
    {name: 'priority'},
    {name: 'ETA'},
    {name: 'team'},
  ],
  priorityList: [
    'regular',
    'important',
    'urgent'
  ],
  typeList: [
    'app',
    'refactor',
    'feature',
    'business',
    'project',
    'prototype',
    'UX/UI',
    'UI',
    'fix',
    'study'
  ],
  phaseList: [
    'discovery',
    'design',
    '??? find dev',
    'implementation',
    'ask offer',
    'send details',
    'development',
    'client review',
    'waiting answer',
    'done'
  ],
  statusList: [
    {label: 'not started'},
    {label: 'hold'},
    {label: 'active'},
    {label: 'completed'}
  ],
  filter: ''
}

export const mutations = {
  SET_DATA (state, data) {
    state.data = data
  },
  SET_ACTIVITY_FIELD (state, {name, value}) {
    state.activity[name] = value
  },
  SET_ACTIVITY (state, data) {
    Object.keys(data).forEach(key => {
      state.activity[key] = data[key]
    })
  },
  UPDATE_ACTIVITY (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  DELETE_ACTIVITY (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  },
  RESET_ACTIVITY (state) {
    state.activity = {}
  },
  ADD_ACTIVITY (state, data) {
    state.data.push(data)
  },
  SET_FILTER (state, {name, value}) {
    state.filter = value ? name : ''
  }
}

export const actions = {
  async get_activities ({commit}) {
    try {
      const { data } = await axios.get(api.activity)
      commit('SET_DATA', data)
    } catch (err) {
      console.log(err)
    }
  },
  async add_activity ({state, commit}) {
    try {
      const { data: { id } } = await axios.post(api.activity, state.activity)
      commit('ADD_ACTIVITY', {...state.activity, id});
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  },
  async update_activity ({state, commit}) {
    try {
      const { data } = await axios.put(`${api.activity}/${state.activity._id}`, state.activity)
      commit('UPDATE_ACTIVITY', data);
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  },
  async delete_activity ({state, commit}) {
    try {
      await axios.delete(`${api.activity}/${state.activity._id}`)
      commit('DELETE_ACTIVITY', state.activity._id);
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  sortByClient: (state) => {
    let results = state.data.sort((a, b) => {
      const clientA = a.client.toLowerCase();
      const clientB = b.client.toLowerCase();

      if (clientA < clientB) return -1;
      if (clientA > clientB) return 1;
      return 0;
    })
    switch (state.filter) {
      case 'all':
        return results;
      case 'active':
        results = results.filter((o) => o.status === 'active');
        break
      case 'completed':
        results = results.filter((o) => o.status === 'completed');
        break
      default:
        results = results.filter((o) => o.status !== 'completed');
    }
    return results;
  }
}